/**
 * @module xwb/ajax
 * @author Guschin Mikhail <misha@arkcom.ru>
 * @version 0.2.0
 * @description
 * # AJAX-расширение
 *
 * @example
 * xwb.ajax("/path/to/resource").done(function(data,statusCode,statusText) {
 *  console.log("Done " + data);
 * }).fail(function(data,statusCode,statusText) {
 *  console.log("Fail " + data);
 * });
 *
 * Or
 *
 * xwb.ajax("/path/to/resource", {
 *           "method": "GET",
 *           "data": "",
 *           "contentType": "application/x-www-form-urlencoded",
 *           "async": true,
 *           "timeout": 30000,
 *           "withCredentials": true,
 *           "done": null,
 *           "fail": null
 *       }).done(function(data,statusCode,statusText) {
 *          console.log("Done " + data);
 *       }).fail(function(data,statusCode,statusText) {
 *          console.log("Fail " + data);
 *       });
 *
 * Or
 *
 * xwb.ajax({
 *           "url": "/path/to/resource",
 *           "method": "GET",
 *           "data": "",
 *           "contentType": "application/x-www-form-urlencoded",
 *           "async": true,
 *           "timeout": 30000,
 *           "withCredentials": true,
 *           "done": function(data,statusCode,statusText) {
 *              console.log("Done " + data);
 *           },
 *           "fail": function(data,statusCode,statusText) {
 *              console.log("Fail " + data);
 *           }
 * });
 */

window.xwb = window.xwb || {};

(function (x) {

  /**
   * Конфигурационный объект AJAX-запроса
   * @typedef RequestSettings
   * @type {Object}
   * @property {String} url URL запроса
   * @property {String} method Метод запроса
   * @property {*} data Данные тела запроса
   * @property {String} contentType Content-Type запроса
   * @property {Boolean} async Асинхронность запроса
   * @property {Number} timeout Время таймаута, с
   * @property {Boolean} withCredentials Должны ли создаваться кросс-доменные Access-Control запросы с использованием таких идентификационных данных как cookie, авторизационные заголовки или TLS сертификаты
   * @property {Function | null} done CallBack функция, в случае успешного выполнения запроса
   * @property {Function | null} fail CallBack функция, в случае ошибки при выполнении запроса
   */

  /**
   * Ответ AJAX-запроса
   * @typedef ResponseData
   * @type {Object}
   * @property {Number|undefined} statusCode Статус ответа (код)
   * @property {String|undefined} statusText Статус ответа (текст)
   * @property {String|undefined} contentType Content-Type ответа
   * @property {*} data Данные ответа
   */

  /**
   * Класс XwbXhr, реализующий функциональность асинхронных запросов, вызовов callback функций
   * @class
   * @memberOf module:xwb/ajax
   * @constructs XwbXhr
   * @constructor
   * @param {String | RequestSettings} par1 URL или JSON с конфигурацией запроса
   * @param {RequestSettings} [par2] JSON с конфигурацией запроса
   * @return {Object | null}
   */
  let XwbXhr = function (par1, par2) {

    if (!(this instanceof XwbXhr)) {
      return new XwbXhr(arguments[0], arguments[1]);
    }

    /**
     * Объект XmlHttpRequest
     * @type {Object | null}
     * @private
     */
    this._xhr = this.getXHRObject();
    if (!this._xhr) return null;

    /**
     * Конфигурация HTTP-запроса
     * @type {RequestSettings}
     * @default {
     * "url": "",
     *   "method": "GET",
     *   "data": "",
     *   "contentType": "application/x-www-form-urlencoded",
     *   "async": true,
     *   "timeout": 30000,
     *   "withCredentials": false,
     *   "done": null,
     *   "fail": null
     * }
     * @private
     */
    this._requestSettings = {
      "url": "",
      "method": "GET",
      "data": "",
      "contentType": "application/x-www-form-urlencoded",
      "async": true,
      "timeout": 30000,
      "withCredentials": false,
      "done": null,
      "fail": null
    };

    /**
     * Данные ответа запроса
     * @type {ResponseData}
     * @default {
     *   "statusCode": undefined,
     *   "statusText": undefined,
     *   "contentType": undefined,
     *   "data": ""
     *}
     * @private
     */
    this._response = {
      "statusCode": undefined,
      "statusText": undefined,
      "contentType": undefined,
      "data": ""
    };

    // определяем конфигурацию запроса:
    let options = {};
    switch (typeof arguments[0]) {
      case 'string':
        if (typeof arguments[1] === "object") options = arguments[1];
        options.url = arguments[0];
        break;
      case 'object':
        options = arguments[0];
        break;
    }
    this.defineSettings(options);

    return this;

  };

  // Прототип класса XwbXhr
  XwbXhr.prototype = {

    /**
     * Возвращает XHR-объект
     * @memberof XwbXhr#
     * @return {Object | null} XHR-объект
     */
    getXHRObject: function () {

      let xhr = null;

      if (window.XMLHttpRequest) {
        xhr = new XMLHttpRequest();
      } else {
        // old IE browsers
        try {
          xhr = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
          try {
            xhr = new ActiveXObject("Microsoft.XMLHTTP");
          } catch (e) {
            xhr = null;
          }
        }
      }

      return xhr;

    },

    /**
     * Определяет конфигурацию AJAX-запроса, формируя свойство _requestSettings
     * @memberof XwbXhr#
     * @param {RequestSettings} conf JSON с конфигурацией запроса
     */
    defineSettings: function (conf) {

      if (typeof conf !== "object") return false;

      let settings = this._requestSettings;

      if (typeof conf.url === "string") settings.url = conf.url;
      if (typeof conf.method === "string") settings.method = (conf.method).toLocaleUpperCase();
      if (conf.data) settings.data = conf.data;
      if (typeof conf.contentType === "string") settings.contentType = conf.contentType;
      if (typeof conf.async === "boolean") settings.async = conf.async;
      if (typeof conf.timeout === "number") settings.timeout = conf.timeout;
      if (typeof conf.done === "function") settings.done = conf.done;
      if (typeof conf.withCredentials === "boolean") settings.withCredentials = conf.withCredentials;
      if (typeof conf.fail === "function") settings.fail = conf.fail;

      return true;
    },

    /**
     * Делает AJAX-запрос
     * @memberof XwbXhr#
     */
    send: function () {

      let _self = this;
      let settings = this._requestSettings;

      // настраиваем XmlHttpRequest в соответствии с конфигурационными настройками
      this._xhr.open(settings.method, settings.url, settings.async);

      // таймаут можно определять только для асинхронных запросов
      if (settings.async) {
        this._xhr.timeout = settings.timeout;
      }

      //this._xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      if (settings.method !== "GET" && settings.method !== "HEAD") this._xhr.setRequestHeader("Content-Type", settings.contentType);

      // вешаем обработчики событий на XmlHttpRequest

      // более менее современные браузеры
      if (typeof this._xhr.onload !== "undefined") {

        // успешное выполнение запроса
        this._xhr.onload = function () {
          _self._onChangeXHRStatus();
        };

        // ошибка при выполнении запроса
        this._xhr.onerror = this._xhr.onload;

        // таймаут при выполнении запроса
        this._xhr.ontimeout = function () {
          _self._onChangeXHRStatus("timeout");
        };

      } else {

        // браузеры-динозавры
        this._xhr.onreadystatechange = function () {
          // состояние ready - ответ получен
          if (_self._xhr.readyState === 4) {
            _self._onChangeXHRStatus();
          }
        }

      }

      // отправляем запрос
      this._xhr.send(settings.data);

    },

    /**
     * Обработчик изменения состояния статуса запроса
     * - Определяет свойство с данными ответа _response
     * - Вызывает Callback функции
     * @param {String} [type] Тип статуса (при таймауте - "timeout", во всех остальных случаях не используется)
     * @memberof XwbXhr#
     * @private
     */
    _onChangeXHRStatus: function (type) {

      // формируем данные ответа
      if (type === "timeout") {
        this._response = {
          statusCode: 408,
          statusText: "Request Timeout",
          contentType: undefined,
          data: undefined
        };
      } else {
        this._response = {
          statusCode: this._xhr.status,
          statusText: this._xhr.statusText,
          contentType: this._xhr.getResponseHeader("Content-Type"),
          data: this._xhr.responseXml || this._xhr.responseText
        };
      }

      // вызываем callback функции, если они были указаны в конфигурации
      this.callBack();

    },

    /**
     * Вызывает Callback функции при получении ответа AJAX-запроса
     * - Вызывает done-функцию, определенную в конфигурации запроса, в случае успешного выполнения запроса
     * - Вызывает fail-функцию, определенную в конфигурации запроса, в случае ошибки при выполнении запроса
     * @memberof XwbXhr#
     */
    callBack: function () {

      let resp = this._response;
      let conf = this._requestSettings;

      if (typeof resp.statusCode === "undefined") return;

      if ((resp.statusCode >= 200 && resp.statusCode < 400) && (typeof conf.done === "function")) {
        conf.done.apply(this, [resp.data, resp.statusCode, resp.statusText]);
      } else if ((resp.statusCode < 200 || resp.statusCode >= 400) && (typeof conf.fail === "function")) {
        conf.fail.apply(this, [resp.data, resp.statusCode, resp.statusText]);
      }
    }

  };


  /**
   * Возвращает _xhr-объект для работы с AJAX
   * @method getXHRObject
   * @memberof module:xwb
   * @return {Object | null} _xhr-объект
   */
  x.getXHRObject = XwbXhr.prototype.getXHRObject;

  /**
   * Далает AJAX-запрос
   * @method ajax
   * @memberof module:xwb/ajax
   * @this ajax
   * @param {String | RequestSettings} par1 URL или JSON с конфигурацией запроса
   * @param {RequestSettings} [par2] JSON с конфигурацией запроса
   * @return {this | null}
   */
  x.ajax = function (par1, par2) {

    /**
     * Экспортируемый объект с методами
     */
    let _export = {

      /**
       * Вызывает callback функцию в случае успешного выполнения запроса
       * @memberof module:xwb/ajax
       * @this ajax
       * @param {Function} func Callback-функция
       * @return {this}
       */
      done: function (func) {
        xwbXhrInst.defineSettings({"done": func});
        xwbXhrInst.callBack();
        return this;
      },

      /**
       * Вызывает callback функцию в случае ошибки при выполнении запроса
       * @memberof module:xwb/ajax
       * @this ajax
       * @param {Function} func Callback-функция
       * @return {this}
       */
      fail: function (func) {
        xwbXhrInst.defineSettings({"fail": func});
        xwbXhrInst.callBack();
        return this;
      }

    };

    // если объекта XmlHttpRequest нет, сразу на выход
    let xwbXhrInst = new XwbXhr(arguments[0], arguments[1]);
    if (xwbXhrInst) {
      xwbXhrInst.send();
      return _export;
    } else {
      return null;
    }

  };

  /**
   * Делает синхронный AJAX-запрос c помощью метода POST
   * @method ajaxCALL
   * @memberof module:xwb/ajax
   * @param {String} url URL запроса
   * @param {*} data Данные тела запроса
   * @returns {*} Результат выполнения запроса
   * @example
   * var res = xwb.ajaxCALL("/","par1=val1&par2=val2")
   */
  x.ajaxCALL = function (url, data) {
    let result;

    this.ajax({
      "url": url,
      "method": "POST",
      "async": false,
      "data": data
    }).done(function (data, statusCode, statusText) {
      result = data;
    }).fail(function (data, statusCode, statusText) {
      result = data;
    });

    return result;
  };

})(window.xwb);